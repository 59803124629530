import React from 'react'
import styled from 'styled-components'
import PostPreview from './PostPreview'

const Posts = styled.div`

    ${PostPreview} {
        margin: 72px auto 0;

        &:first-child {
            margin: 0 auto;
        }

        @media(min-width: 600px) {
            max-width: 300px;
        }
    }

    @media(min-width: 834px) {
        display: flex;
        flex-wrap: wrap;
        margin:-40px -2%;
        ${PostPreview} {
            max-width: unset;
            flex: 0 0 25.333333333%;
            margin: 40px 4% 0;

            &:nth-child(2), &:nth-child(3)  {
                margin-top: 0;
            }
        }
    }
`
function PostPreviewList({ posts, className }) {

    return <Posts className={className}>
        {posts.map((p, i) => <PostPreview key={i} {...p} />)}
    </Posts>
}

export default styled(PostPreviewList)``