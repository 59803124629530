import React from 'react'
import styled from 'styled-components'
import Container from '../ui/Container'
import Sharer from '../ui/Sharer'
import PrismicText from '../ui/PrismicText'
import PostAuthor from '../ui/PostAuthor'
import Avatar from '../ui/Avatar'

const Image = styled.img`
    width: 100%;
    height: auto;
    margin: 29px auto 0;
    box-shadow: 0px 7px 4px rgba(34, 34, 52, 0.13);
`
const Attribution = styled.div`
    margin: 10px auto 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.43px;
    text-transform: uppercase;
    color: #250044;
    a {
        color: #6786FF;
    }
`
const Title = styled.div`
    font-family: "EB Garamond";
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #250044;
    margin: 51px auto 0;
`
const Text = styled(PrismicText)`
    margin: 60px auto 0;
`
const Wrapper = styled.div`

    ${Container} > ${Avatar} {
        background: #D4DBF5;
        margin-top: 27px;
    }

    @media (min-width: 600px) {

        ${Title} {
            width: 60%;
        }        
    }

    @media (min-width: 834px) {

        ${Title} {
            margin-top: 68px;
            width: 40%;
        }

        ${Image} {
            margin: 61px auto 0;
        }
    }
`
function Post({ title, image, html: __html, date, author_name, author_picture, author_position, author_description, author_link }) {

    return <Wrapper>
        <Container>
            <Title>{title}</Title>
            {author_picture &&

                <Avatar src={author_picture} size={40} />
            }
            <Attribution>By {author_link ? <a href={author_link}>{author_name}</a> : <span>{author_name}</span>}, {date}</Attribution>
            <Image src={image} />
            <Text dangerouslySetInnerHTML={{ __html }} />
            <Sharer />
            {author_description && author_name && author_picture && author_position &&
                <PostAuthor
                    author_description={author_description}
                    author_name={author_name}
                    author_picture={author_picture}
                    author_position={author_position}
                />
            }
        </Container>
    </Wrapper>
}

export default Post