import styled from 'styled-components'

const SecondaryHeading = styled.h2`
    text-align: center;
    font-family: "EB Garamond";
    font-weight: 600;
    line-height: 30px;
    font-size: 22px;
    color: #250044;
`

export default SecondaryHeading