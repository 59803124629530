import React from 'react'
import styled from 'styled-components'
import Link from '../ui/Link'

const Thumb = styled.div`
    width: 100%;
    padding-bottom: 66.3%;
    background-image: url(${({ image }) => image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
`
const Attribution = styled.div`
    margin-top: 20px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.43px;
    text-transform: uppercase;
    color: #250044;
`
const Title = styled.div`
    margin-top: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -1px;
    color: #250044;
`
const PostLink = styled(Link)`
    text-decoration: none;
`
const Wrapper = styled.div`

    @media(min-width: 834px) {
        ${Attribution} {
            margin-top: 12px;
            text-align: left;
        }

        ${Title} {
            margin-top: 12px;
            text-align: left;
        }
    }
`
function PostPreview({ previewImage, url, author, title, className, author_name, date }) {

    return <Wrapper className={className}>
        <PostLink to={url}>
            <Thumb image={previewImage} />
            <Attribution>By {author_name}, {date}</Attribution>
            <Title>{title}</Title>
        </PostLink>
    </Wrapper>
}

export default styled(PostPreview)``