import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import ItalicHeading from "../components/ui/ItalicHeading"
import Container from "../components/ui/Container"
import Post from "../components/sections/Post"
import styled from "styled-components"
import PostPreviewList from "../components/ui/PostPreviewList"
import Button from "../components/ui/Button"
import { edgeToPostPreview, edgeToPost } from "../lib/utils"
import SecondaryHeading from "../components/ui/SecondaryHeading"
import { graphql } from 'gatsby'

const Wrapper = styled.div`
    padding: 0 0 60px;
    text-align: center;

    ${ItalicHeading} {
        margin: 60px auto 0;
    }

    ${Button}{ 
        margin: 60px auto 0;
    }

    ${Container} {
        overflow: hidden;
        
        ${SecondaryHeading} {
            margin: 71px auto 0;
        }

        ${PostPreviewList} {
            margin-top: 55px;
        }
    }

    @media(min-width: 834px) {

        ${Container} {
            overflow: hidden;
            
            ${SecondaryHeading} {
                margin: 114px auto 0;
            }

            ${PostPreviewList} {
                margin: 60px -4% 0; 
            }
        }
    }
`

const PostPage = ({ data }) => {

    const post = edgeToPost({ edge: data.main.edges[0] })
    const { title, image } = post

    const previews = data.more.edges.map(edge => edgeToPostPreview({ edge }))

    return <Layout>
        <SEO title={title} image={image} />
        <Wrapper>
            <ItalicHeading>Expert tips</ItalicHeading>
            <Post {...post} />
            <Container>
                <SecondaryHeading>Expert Tips</SecondaryHeading>
                <PostPreviewList posts={previews} />
                <Button to="/blog">See all blog posts</Button>
            </Container>
        </Wrapper>
    </Layout>
}


export const query = graphql`
  query PostPageQuery($id: String) {
    main: allPrismicPost(filter: {id: {eq: $id}}) {
            edges {
            node {
                data {
                    title {
                        text
                    }
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 2048) {
                                    src
                                }
                            }
                        }
                    }
                    content {
                        html
                    }
                    author_name {
                        text
                    }
                    author_picture {
                        localFile {
                            childImageSharp {
                                fixed {
                                    src
                                }
                            }
                        }
                    }
                    author_link {
                        url
                    }
                    author_description {
                        html
                    }
                    author_position {
                        text
                    }
                    date(formatString: "MM/DD/YYYY")
                }
                prismicId
                uid
                id
            }
        }
    }

    more: allPrismicPost(limit: 3, sort: {fields: data___date, order: DESC}) {
      edges {
        node {
          data {
            title {
              text
            }
            previewimage {
                localFile {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 834) {
                            src
                        }
                    }
                }
            }
            author_name {
                text
            }
            date(formatString: "MM/DD/YYYY")
          }
          prismicId
          uid
        }
      }
    }
}
`

export default PostPage