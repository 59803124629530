import React from 'react'
import styled from 'styled-components'
import Avatar from './Avatar'
import PrismicText from './PrismicText'

const Name = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 10px;
`
const Position = styled.div`
    margin-top: 2px;
    font-size: 14px;
    line-height: 17px;
`
const Description = styled(PrismicText)`
    margin-top: 31px;
    font-size: 14px;
    line-height: 17px;
`
const Wrapper = styled.div`

    ${Avatar} {
        background: #D4DBF5;
        margin-top: 63px;
    }

    @media(min-width: 834px) {
        ${Avatar} {
            margin-top: 92px;
        }
        ${Name} {
            margin-top: 27px;
        }
        ${Position} {
            margin-top: 5px;
        }
        ${Description} {
            margin-top: 51px;
        }
    }
`

function PostAuthor({ className, author_picture, author_name, author_position, author_description }) {

    return <Wrapper className={className}>
        <Avatar src={author_picture} size={200} />
        <Name>{author_name}</Name>
        <Position>{author_position}</Position>
        <Description dangerouslySetInnerHTML={{ __html: author_description }} />
    </Wrapper>
}

export default styled(PostAuthor)``