import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useClipboard } from 'use-clipboard-copy';

const ShareIcon = styled.img`
    cursor: pointer;
`
const ShareLink = styled.a`
    cursor: pointer;
`
const Copied = styled.div`
    position: absolute;
    z-index: 2;
    width: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-size: 12px;
`
const ShareCopy = styled.div`
    position: relative;
    
    ${ShareIcon} {
        opacity: ${(({ copied }) => copied ? '.25' : '1')};
    }

    ${Copied} {
        display: ${(({ copied }) => copied ? 'block' : 'none')};
    }
`
const Links = styled.div`
    margin: 31px auto 0;
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const Wrapper = styled.div`
    margin: 88px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const Title = styled.div`
    font-family: 'EB Garamond', serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;

    img {
        margin-left: 5px;
    }
`

function Sharer({ className }) {

    const clipboard = useClipboard({ copiedTimeout: 600 })
    const [url, setUrl] = useState()

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
    const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`

    const onCopyClick = () => {
        clipboard.copy(url);
    }

    return <Wrapper className={className}>
        <Title>Share <img src="/share/share.svg" alt="share icon" /></Title>
        <Links>
            <ShareLink href={facebookUrl} target="_blank" rel="noopener noreferrer"><ShareIcon src="/share/facebook.svg" alt="facebook share icon" /></ShareLink>
            <ShareLink href={twitterUrl} target="_blank" rel="noopener noreferrer"><ShareIcon src="/share/twitter.svg" alt="twitter share icon" /></ShareLink>
            <ShareCopy onClick={onCopyClick} copied={clipboard.copied}>
                <ShareIcon src="/share/copy.svg" alt="copy icon" />
                <Copied>Copied!</Copied>
            </ShareCopy>
        </Links>
    </Wrapper>
}

export default styled(Sharer)``